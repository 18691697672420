// 需要安装 axios 插件 npm i axios
import axios from "axios";
import { getStorage } from "../../utils/storage";

const http = axios.create({
  baseURL: "",
  //超时的时间
  timeout: 4000,
});

const addEkeyAndSoftId = (url) => {
  const unHaveParams = url.indexOf("?") === -1;
  if (unHaveParams) return url + `?ekey=${getStorage("ekey")}`;
  const unHaveEkeys = url.indexOf("ekey=") === -1;
  if (unHaveEkeys) return url + `&ekey=${getStorage("ekey")}`;
  return url;
};

// request interceptor
http.interceptors.request.use(
  (config) => {
    config.url = addEkeyAndSoftId(config.url);
    if (getStorage("token")) {
      config.headers["Authorization"] = getStorage("token");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
