<template>
	<div>
		<div class="navbr-main">
		      <img src="../../assets/wallpaper-logo.png" class="wallpaper-logo"/>
				<div class="wallpaper-name">123动态桌面壁纸</div>
				<div class="wallpaper-search" @click="getFocus(true)" ref="boxs">
					<input placeholder="大家都在搜索：原神" v-model="keywords" style="background-color: #373a3d;" class="wallpaper-search-ipt"  @focus="getFocus(true)" @keyup.enter="searchsPaper"/>
					<img src="../../assets/wallpaper-search-img.png" class="search-imgs gestures" @click.stop="searchsPaper"/>
					
					<div class="hot-search-main" v-show="isFocus">
						<div class="hot-search-tel">今日热搜</div>
						<div class="hot-search-item" v-for="(value,index) in hotList" :key="index" @mouseover="mouseMenu('hotListIndex',index)" @click="changeHot(value)">
							<div class="ranking-rank" :class="[index<3?'ranking-rank-'+index:'ranking-rank-mo']">{{index+1}}</div>
							<div class="hot-search-name gestures" :class="{'search-name-sel':index==hotListIndex}">{{value.text}}</div>
						</div>
					</div>
				</div>
				<div class="wallpaper-nav-ret">
					<div class="nav-ret-list gestures">
						<img src="../../assets/navbar/menu-icon.png" @click="changeMenu"/>
						<img :src="showMian?require('../../assets/navbar/minimize-icon-sel.png'):require('../../assets/navbar/minimize-icon.png')" 
						 @mouseover="mouseOverGame('showMian',true)"  @mouseleave="mouseOverGame('showMian',false)"/>
						<img src="../../assets/navbar/app-close.png"/>
					</div>
					<div class="nav-ret-boom gestures" @click="reportShow = true">版权声明<span class="lines"></span>侵权举报</div>
					<div class="fixed-menu-list" v-show="showMenu" >
						<div class="fixed-menu-item gestures" :class="{'fixed-menu-sel':index==menuListIndex}"
						@mouseover="mouseMenu('menuListIndex',index)" 
						v-for="(item,index) in menuList" :key="index" @click="clickMenu(index)">{{item.text}}</div>
					</div>
				</div>
		</div>
		<el-dialog
		  title=""
		  :visible.sync="setupShow"
		  width="660px"
		  @close="setupShow = false"
		  :show-close="false">
		  
		  <div class="setup-main">
			  <div class="setup-main-left">
				  <div class="setup-left-head">
					  <img src="../../assets/wallpaper-logo.png"/>
					  <div class="setup-tel">设置中心</div>
				  </div>
				  <div class="setup-tab gestures" v-for="(value,index) in setupTab" :key="index" 
				  :class="{'setup-tab-sel':setupTabIndex==index}" @click="changeSetupTab(index)">{{value.text}}</div>
			  </div>
			  <div class="setup-main-ret">
				  <div class="setup-head-close">
					  <img src="../../assets/navbar/setup-close.png" class="gestures" @click="setupShow = false"/>
				  </div>
				  <!-- 基本设置 -->
				  <div class="setup-content" v-show="setupTabIndex == 0">
					  <div class="setup-content-tel">壁纸设置</div>
					  <div class="setup-content-item" v-for="(value,index) in setupData" :key="index">
						  <div class="content-item-text">{{value.text}}</div>
						  <el-switch
						    v-model="value.value"
						    active-color="#0EAAF3">
						  </el-switch>
					  </div>
					  <div class="setup-content-tel">推送设置</div>
					  <div class="setup-content-item" v-for="(item,key) in pushData" :key="key">
					  		<div class="content-item-text">{{item.text}}</div>
					  		<el-switch
					  		  v-model="item.value"
					  		  active-color="#0EAAF3">
					  		</el-switch>				  
					  </div>
				  </div>
				  
				  <!-- 锁屏设置 -->
				  <div class="setup-content" v-show="setupTabIndex == 1">
					  <div class="setup-content-tel">锁屏设置</div>
					  <div class="setup-content-item">
					  		<div class="content-item-text">静止后自动进入锁屏模式</div>
					  		<el-switch
					  		  v-model="value"
					  		  active-color="#0EAAF3">
					  		</el-switch>				  
					  </div>
					  <div class="setup-content-tel">请选择几分钟误操作后，自动壁纸锁屏</div>
					  <div class="screen-min gestures" v-for="(value,index) in minuteTab" :key="index" @click="changeMin(index)">
						  <img src="../../assets/navbar/setup-sel-no.png" v-show="index!=minuteTabIndex"/>
						  <img src="../../assets/navbar/setup-sel-yes.png" v-show="index==minuteTabIndex"/>
						  <div class="screen-min-text">{{value.text}}</div>
					  </div>
				  </div>
				  
				  <!-- 空间设置 -->
				  <div class="setup-content" v-show="setupTabIndex == 2">
					  <div class="setup-content-tel">下载设置</div>
					  <div class="download-tips">将壁纸下载到此目录下<span class="download-open gestures" >打开目录</span></div>
					  <div class="download-items">
					  		<div class="download-item-left">D:\123Downloads</div>
					  		<div class="download-item-ret gestures">选择目录</div>
					  </div>
					  <div class='free-space'>可用空间：333.44GB</div>
					  <div class="download-tip-text">将本地资源自动转移到新路径下，这可能需要花费一些时间</div>
				  </div>
				  
				  <!-- 关于我们 -->
				  <div class="setup-content" v-show="setupTabIndex == 3">
					  <div class="about-tel">123动态壁纸桌面</div>
					  <div class="about-item">版本号：3.1.11.2.16</div>
					  <div class="about-item">官网 <span>www.123dtbz.com</span></div>
					  <div class="about-item">官方交流群：</div>
					  <div class="about-tel">其他</div>
					  <div class="agreement-text">《用户协议》丨《隐私协议》</div>
				  </div>
			  </div>
		  </div>
		</el-dialog>
		
		<el-dialog
		  title=""
		  :visible.sync="reportShow"
		  width="700px"
		  @close="reportShow = false"
		  :show-close="false">
		  <div class="report-main">
			  <div class="report-head">
				  侵权举报
				  <i class="el-icon-close report-head-close gestures" @click="reportShow = false"></i>
			  </div>
			  <div class="report-content">
				  <div class="report-content-tel">*<span>举报举证：</span></div>
				  <div class="report-conten-item">
					  <div class="report-content-multiple">
						  <textarea placeholder="请留下线索（如原作品链接），方便工作人员尽快进行核查" class="multiple-val"></textarea>
					  </div>
				  </div>
				  <div class="report-content-tel">*<span>证明材料：</span></div>
				  <div class="report-conten-item">
					  <div class="upload-list">
						  <div class="upload-item gestures" :class="{'upload-item-sel':contact}" @mouseover="mouseUpload(true)"  @mouseleave="mouseUpload(false)">
							  <i class="el-icon-plus upload-item-icon"></i>
						  </div>
					  </div>
				  </div>
				  <div class="report-content-tel">*<span>证明材料：</span></div>
				  <div class="report-conten-item">
				  		<div class="report-content-ipt">
							<input placeholder="请留下QQ号以便客服联系及时处理" class="content-ipt-val"/>
						</div>
				  </div>
				  <div class="report-boom">
					  <div class="report-boom-btn gestures">确认提交</div>
				  </div>
			  </div>
			  
		  </div>
		</el-dialog>
		
	</div>
  
</template>

<script>
	export default {
		data(){
			return {
				showMenu:false,
				menuList:[
					{text:'软件设置'},
					{text:'自动更换'},
					{text:'上传壁纸'},
					{text:'意见反馈'},
					{text:'商务合作'},
				],
				menuListIndex:-1,
				hotList:[
					{text:'原神'},
					{text:'美女'},
					{text:'王者荣耀'},
					{text:'二次元动漫'},
					{text:'英雄联盟'},
					{text:'海贼王'},
				],
				hotListIndex:-1,
				isFocus:false,
				setupShow:false,
				setupTab:[
					{text:'基本设置'},
					{text:'锁屏设置'},
					{text:'空间设置'},
					{text:'关于我们'},
				],
				setupTabIndex:3,
				value:false,
				setupData:[
					{text:'开机后启动123动态壁纸桌面',value:false},
					{text:'动态壁纸优先启用硬件加速',value:false},
					{text:'其他程序全屏时壁纸将变成静态',value:false},
				],
				pushData:[
					{text:'右下角动态壁纸推荐',value:false},
					{text:'办公软件应用推荐',value:false},
				],
				minuteTab:[
					{text:'1分钟'},
					{text:'3分钟'},
					{text:'5分钟'},
					{text:'10分钟'},
					{text:'15分钟'},
					{text:'30分钟'},
				],
				minuteTabIndex:0,
				keywords:'',
				showClose:false,
				showMian:false,
				reportShow:false,
				contact:false
			}
		},
		mounted(){
			
			document.addEventListener("click", (e) => {
				// console.log(e)
			    if (!this.$refs.boxs.contains(e.target)){
					this.isFocus = false;
				}
				
			});
		},
		created() {
			
		},
		methods:{
			mouseUpload(type){
				this.contact = type
			},
			mouseOverGame(ref,type){
				this[ref] = type
			},
			searchsPaper(){
				if(this.keywords==''){
					return false
				}
				this.isFocus = false
				this.jumpSearchDetails(this.keywords)
				
			},
			jumpSearchDetails(val){
				if(this.$route.name=='searchDetails'){
					this.$store.commit('saveKeyword',val)
				}else{
					this.$router.push({ name: 'searchDetails',query:{keyword:val}});
				}
			},
			changeHot(data){
				this.jumpSearchDetails(data.text)
				this.isFocus = false
			},
			clickMenu(index){
				if(index == 0){
					this.setupShow = true
				}
			},
			changeMin(index){
				if(this.minuteTabIndex!=index){
					this.minuteTabIndex=index
				}
			},
			changeSetupTab(index){
				if(this.setupTabIndex!=index){
					this.setupTabIndex=index
				}
			},
			changeMenu(){
				this.showMenu  = !this.showMenu
			},
			mouseMenu(ref,index){
				if(this[ref] != index){
					this[ref] = index
				}
			},
			getFocus(type){
				this.isFocus = type
			},
		}
	}
</script>
<style lang="scss">
	.report-boom{
		margin-top: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		.report-boom-btn{
			width: 150px;
			height: 38px;
			background: #FF5F61;
			border-radius: 5px;
			text-align: center;
			line-height: 38px;
			color: #FFFFFF;
			font-size: 14px;
		}
	}
	.report-content{
		background-color: #FFFFFF;
		border-radius: 6px;
		padding: 4px 36px 55px 18px;
		.report-content-ipt{
			background: #F5F5F6;
			border-radius: 5px;
			padding: 12px;
			.content-ipt-val{
				font-size: 12px;
				background: #F5F5F6;
				width: 100%;
			}
		}
		.upload-list{
			display: flex;
			align-items: center;
		}
		.upload-item{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 170px;
			height: 100px;
			border: 2px dashed #EEEEEE;
			.upload-item-icon{
				color: #0EAAF3;
				font-size: 30px;
			}
		}
		.upload-item-sel{
			border: 2px dashed #0EAAF3;
		}
		.report-conten-item{
			margin-top: 14px;
			margin-left: 58px;
			.report-content-multiple{
				background: #F5F5F6;
				border-radius: 5px;
				padding: 10px 12px;
				.multiple-val{
					width: 100%;
					height: 120px;
					font-size: 12px;
					background: #F5F5F6;
				}
			}
		}
		.report-content-tel{
			color: #FF5F61;
			font-size: 14px;
			margin-top: 20px;
			span{
				color: #333333;
				font-weight: bold;
				margin-left: 4px;
			}
		}
	}
	.report-main{
		background: #FF5F61;
		border-radius: 8px;
		padding: 4px;
		.report-head{
			padding: 10px 0px;
			text-align: center;
			color: #FFFFFF;
			font-size: 14px;
			font-weight: bold;
			position: relative;
			.report-head-close{
				position: absolute;
				right: 4px;
				top: 10px;
				font-size: 20px;
			}
		}
	}
	.about-item{
		margin-top: 18px;
		color: #1E2644;
		font-size: 13px;
		span{
			color: #0EAAF3;
			border-bottom: 1px solid #0EAAF3;
			margin-left: 8px;
		}
	}
	.agreement-text{
		margin-top: 18px;
		color: #C6C6C6;
		font-size: 13px;
	}
	.download-tip-text{
		margin-top: 10px;
		color: #898989;
		font-size: 12px;
	}
	.download-tips{
		margin-top: 20px;
		color: #1E2644;
		font-size: 13px;
	}
	.download-open{
		margin-left: 14px;
		color: #0EAAF3;
	}
	.download-items{
		display: flex;
		align-items: center;
		margin-top: 12px;
		.download-item-left{
			width: 293px;
			padding: 8px 10px;
			border: 1px solid #D8D8D8;
			border-radius: 3px;
			color: #999999;
			font-size: 12px;
		}
		.download-item-ret{
			margin-left: 10px;
			width: 95px;
			padding: 8px 0px;
			border: 1px solid #D8D8D8;
			border-radius: 3px;
			text-align: center;
			color: #0EAAF3;
			font-size: 12px;
		}
	}
	.free-space{
		margin-top: 5px;
		color: #1E2644;
		font-size: 12px;
	}
	.screen-min{
		display: flex;
		align-items: center;
		margin-top: 11px;
		img{
			width: 15px;
			height: 15px;
		}
		.screen-min-text{
			margin-left: 8px;
			color: #1E2644;
			font-size: 13px;
		}
	}
	.about-tel{
		padding-top: 14px;
		color: #333333;
		font-size: 14px;
		font-weight: bold;
	}
	.setup-main{
		display: flex;
		height: 450px;
		box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
		border-radius: 4px;
		.setup-content{
			padding: 0px 46px 0px 30px;
			.setup-content-tel{
				padding: 14px 0px 8px 0px;
				color: #333333;
				font-size: 14px;
				font-weight: bold;
			}
			.setup-content-item{
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 10px;
				.content-item-text{
					color: #1E2644;
					font-size: 13px;
				}
			}
		}
		.setup-main-ret{
			flex: 1;
			.setup-head-close{
				display: flex;
				justify-content: flex-end;
				padding: 10px 12px 0px 0px;
				img{
					width: 34px;
					height: 28px;
				}
			}
		}
		.setup-main-left{
			width: 103px;
			padding: 0px 7px;
			background-color: #F4F4F4;
			height: 450px;
			.setup-left-head{
				display: flex;
				align-items: center;
				justify-content: center;
				padding-top: 10px;
				img{
					width: 15px;
					height: 15px;
				}
				.setup-tel{
					margin-left: 8px;
					color: #1E2644;
					font-size: 14px;
				}
			}
			.setup-tab{
				margin-top: 20px;
				height: 29px;
				text-align: center;
				line-height: 29px;
				border-radius: 2px;
				color: #1E2644;
				font-size: 13px;
			}
			.setup-tab-sel{
				background-color: #0EAAF3;
				color: #FFFFFF;
			}
		}
	}
	.ranking-rank-0{
		background-color: #FF7643!important;
	}
	.ranking-rank-mo{
		background-color: #58CFFF!important;
	}
	.ranking-rank-1{
		background-color: #FFA101!important;
	}
	.ranking-rank-2{
		background-color: #FFC000!important;
	}
	.hot-search-item{
		display: flex;
		align-items: center;
		margin-top: 12px;
	}
	.hot-search-name{
		margin-left: 14px;
		color: #4D5571;
		font-size: 14px;
	}
	.search-name-sel{
		color: #0EAAF3!important;
	}
	.ranking-rank{
		width: 15px;
		// padding: 1px 0px;
		text-align: center;
		border-radius: 2px;
		color: #FFFFFF;
		font-size: 12px;
		background-color: #FF7643;
	}
	.hot-search-main{
		position: absolute;
		width: 264px;
		background: #FFFFFF;
		box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.16);
		border-radius: 5px 5px 5px 5px;
		padding: 16px 0px 14px 26px;
		top: 45px;
		left: 0;
		.hot-search-tel{
			color: #FF6565;
			font-size: 16px;
			font-weight: bold;
		}
	}
	.fixed-menu-list{
		position: absolute;
		top: 20px;
		right: 0;
		width: 115px;
		padding: 3px;
		// height: 156px;
		background: #2D363C;
		border-radius: 6px 6px 6px 6px;
		.fixed-menu-item{
			padding: 7px 0px;
			text-align: center;
			color: #B2B8BE;
			font-size: 12px;
		}
		.fixed-menu-sel{
			background-color: #39434A;
		}
	}
	.nav-ret-boom{
		display: flex;
		align-items: center;
		padding: 5px 7px;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #8A9297;
		color: #8A9297;
		font-size: 12px;
		margin-top: 8px;
		.lines{
			width: 1px;
			height: 16px;
			margin: 0px 7px;
		}
	}
	.nav-ret-list{
		display: flex;
		justify-content: flex-end;
		img{
			width: 18px;
			height: 18px;
			margin-left: 3px;
		}
	}
	.wallpaper-nav-ret{
		margin-left: auto;
		position: relative;
	}
	.navbr-main{
		height: 72px;
		position: fixed;
		top: 0;
		right: 0;
		z-index: 999;
		left: 0;
		background-color: #202427;
		padding: 0px 12px 0px 30px;
		display: flex;
		align-items: center;
	}
	.wallpaper-logo{
		width: 28px;
		height: 28px;
	}
	.wallpaper-name{
		margin-left: 8px;
		color: #FFFFFF;
		font-size: 16px;
		font-weight: bold;
	}
	.wallpaper-search{
		margin-left: 40px;
		width: 260px;
		height: 38px;
		display: flex;
		align-items: center;
		background-color: #373a3d;
		border-radius: 19px;
		padding: 0px 18px;
		position: relative;
		.wallpaper-search-ipt{
			font-size: 12px;
			width: 80%;
			color: #FFFFFF;
		}
		.search-imgs{
			margin-left: auto;
			width: 14px;
			height: 14px;
		}
	}
</style>
