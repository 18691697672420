<template>
	<div class="app-wrapper">
		<Navbar />
		<div class="main-container">
			<Sidebar />
			<AppMain />
			<!-- <router-view></router-view> -->
		</div>
		
	</div>
</template>

<script>
	import Navbar from "@/layout/components/Navbar.vue"
	import Sidebar from "@/layout/components/Sidebar.vue"
	import AppMain from "@/layout/components/AppMain.vue"
	export default {
		components:{Sidebar,AppMain,Navbar},
		data(){
			return{
				
			}
		},
		methods:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.app-wrapper {
	  position: relative;
	  height: 100%;
	  width: 100%;
	  // display: flex;
	}
	.main-container{
		min-height: 100%;
		position: relative;
	}
</style>
