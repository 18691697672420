import http from "../utils/axios"
import config from "../config"
import jsonJoin from "../utils/jsonJoin"

// 获取微信登录二维码
const userGetLoginCodeApi = () => {
  const params = {
    device_id: config.device_id,
    mac_code: config.mac_code,
    device_code: config.device_code,
    ekey: config.ekey,
    source: config.source,
    mp: config.mp,
    bdVid: config.bdVid,
    strategy: config.strategy,
    soft_id: config.soft_id,
    curr_ver: "1.0.0.0",
    os: config.os,
  }
  return http.get(`${config.wxLoginAndPayDomain}/api/ewm?${jsonJoin(params)}`)
}

// 获取扫码token
const userGetLoginTokenApi = (params) => {
  return http.get(
    `${config.wxLoginAndPayDomain}/api/getToken?${jsonJoin(params)}`
  )
}

// 获取手机登录验证码
const getVerifyCodeApi = (params) => {
  return http.get(`${config.wxLoginAndPayDomain}/api/sms?${jsonJoin(params)}`)
}

// 进行手机登录
const phoneLoginApi = (data) => {
  const params = {
    curr_ver: config.curr_ver,
    device_id: config.device_id,
    soft_id: config.soft_id,
    mac_code: config.mac_code,
    os: config.os,
    source: config.source,
    device_code: config.device_code,
    phone: data.phone,
    code: data.code,
  }
  return http.get(
    `${config.wxLoginAndPayDomain}/api/login_phone?${jsonJoin(params)}`
  )
}

export {
  userGetLoginCodeApi,
  userGetLoginTokenApi,
  getVerifyCodeApi,
  phoneLoginApi,
}
