const useUniStorage = () => {
  const getStorage = (key) => {
    return uni.getStorageSync(key);
  };
  const setStorage = (key, data) => {
    uni.setStorageSync(key, data);
  };
  const removeStorage = (key) => {
    uni.removeStorageSync(key);
  };
  const clearPubilcStorage = () => {
    const list = [
      "device_code",
      "installStatus",
      "source",
      "onlineTime",
      "soft_id",
      "deviceType",
      "ekey",
    ];
    list.forEach((item) => removeStorage(item));
  };

  return { getStorage, setStorage, removeStorage, clearPubilcStorage };
};

export default useUniStorage;
