import Vue from 'vue'
import Vuex from 'vuex'
import { getStorage, setStorage, removeStorage } from "../../csdPublic/utils/storage"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  userInfo: getStorage('userInfo') ? JSON.parse(getStorage('userInfo')) : {},
	  vipInfo:getStorage('vipInfo') ? JSON.parse(getStorage('userInfo')) : {},
	  isLogin:false,
	  keywordVal:''
  },
  mutations: {
	  //保存用户登录信息
	  saveUserInfo(state,userInfo){
		  state.userInfo = userInfo;
	  },
	  //保存用户vip信息
	  saveVipInfo(state,vipInfo){
	  	  state.vipInfo = vipInfo;
	  },
	  //保存用户登录状态
	  saveIsLogin(state,isLogin){
	  	  state.isLogin = isLogin;
	  },
	  // 用户搜索框传值到搜索页面
	  saveKeyword(state,val){
	  	  state.keywordVal = val
	  },
  },
  actions: {
  },
  modules: {
  }
})
