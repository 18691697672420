import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index'
// ../layout/index
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
  	component: Layout,
  	children: [
  	  {
  	    path: '/',
  	    component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue'),
  	    name: 'home',
  	  }
  	]
  },
  {
    path: '/authorRecommend/index',
    name: 'authorRecommend',
  	component: Layout,
  	children: [
  	  {
  	    path: '/authorRecommend/index',
  	    component: () => import(/* webpackChunkName: "about" */ '../views/authorRecommend/index.vue'),
  	    name: 'authorRecommend',
  	  }
  	]
  },
  {
    path: '/authorDetails/index',
    name: 'authorDetails',
  	component: Layout,
  	children: [
  	  {
  	    path: '/authorDetails/index',
  	    component: () => import(/* webpackChunkName: "about" */ '../views/authorDetails/index.vue'),
  	    name: 'authorDetails',
  	  }
  	]
  },
  {
    path: '/personal/index',
    name: 'personal',
  	component: Layout,
  	children: [
  	  {
  	    path: '/personal/index',
  	    component: () => import(/* webpackChunkName: "about" */ '../views/personal/index.vue'),
  	    name: 'personal',
  	  }
  	]
  },
  {
    path: '/details/index',
    name: 'details',
  	component: Layout,
  	children: [
  	  {
  	    path: '/details/index',
  	    component: () => import(/* webpackChunkName: "about" */ '../views/details/index.vue'),
  	    name: 'details',
  	  }
  	]
  },
  {
    path: '/staticState/index',
    name: 'staticState',
  	component: Layout,
  	children: [
  	  {
  	    path: '/staticState/index',
  	    component: () => import(/* webpackChunkName: "about" */ '../views/staticState/index.vue'),
  	    name: 'staticState',
  	  }
  	]
  },
  {
    path: '/searchDetails/index',
    name: 'searchDetails',
  	component: Layout,
  	children: [
  	  {
  	    path: '/searchDetails/index',
  	    component: () => import(/* webpackChunkName: "about" */ '../views/searchDetails/index.vue'),
  	    name: 'searchDetails',
  	  }
  	]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
