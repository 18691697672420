import { getUserInfoApi, getVipInfoApi } from "./api/user.js";

const getUserInfo = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await getUserInfoApi();
      const data = res.data;
      if (data.code >= 300) {
        reject({
          code: data.code,
          msg: data.msg,
        });
      }
      resolve(data.data);
    } catch (error) {
      reject({
        msg: "请求失败",
      });
    }
  });
};

const getVipInfo = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await getVipInfoApi();
      const data = res.data;
      if (data.code >= 300) {
        reject({
          code: data.code,
          msg: data.msg,
        });
      }
      resolve(data.data);
    } catch (error) {
      reject({
        msg: "请求失败",
      });
    }
  });
};
export { getUserInfo, getVipInfo };
