import { getEkey, getUUID } from "../utils"
import { getStorage, setStorage } from "../utils/storage"
import config from "./index"

// 初始化 source、bdVid、strategy、mp
const initSource = () => {
  const fromSource = window?.location?.href
    ?.split("?")[1]
    ?.split("#")[0]
    ?.split("&")
    ?.map((item) => {
      return [item.split("=")[0], item.split("=")[1]]
    })
  const source = fromSource?.find((item) => item[0] === "from")
  if (source) {
    config.source = source[1]
  }
  const bdVid = fromSource?.find((item) => item[0] === "bd_vid")
  if (bdVid) {
    config.bdVid = bdVid[1]
  }
  const strategy = fromSource?.find((item) => item[0] === "strategy")
  if (strategy) {
    config.strategy = strategy[1]
  }
  const mp = fromSource?.find((item) => item[0] === "mp")
  if (mp) {
    config.mp = mp[1]
  }
}

const initEkey = () => {
  config.ekey = getEkey()
  setStorage("ekey", config.ekey)
}

const initDeviceId = () => {
  if (typeof wx == "object") {
    config.device_id = "applet"
    return
  }
  // 小程序环境会报错
  var uA = navigator.userAgent.toLowerCase()
  var ipad = uA.match(/ipad/i) == "ipad"
  var iphone = uA.match(/iphone os/i) == "iphone os"
  var midp = uA.match(/midp/i) == "midp"
  var uc7 = uA.match(/rv:1.2.3.4/i) == "rv:1.2.3.4"
  var uc = uA.match(/ucweb/i) == "ucweb"
  var android = uA.match(/android/i) == "android"
  var windowsce = uA.match(/windows ce/i) == "windows ce"
  var windowsmd = uA.match(/windows mobile/i) == "windows mobile"
  if (
    !(ipad || iphone || midp || uc7 || uc || android || windowsce || windowsmd)
  ) {
    var agent = navigator.userAgent.toLowerCase()
    var isMac = /macintosh|mac os x/i.test(navigator.userAgent)
    if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
      config.device_id = "Window32"
      return
    }
    if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
      config.device_id = "Window64"
      return
    }
    if (isMac) {
      config.device_id = "Mac"
      return
    }
  } else {
    const ua = navigator.userAgent.toLowerCase()
    if (/android|adr/gi.test(ua)) {
      config.device_id = "Android_Phone"
      return
    } else if (/\(i[^;]+;( U;)? CPU.+Mac OS X/gi.test(ua)) {
      config.device_id = "IOS_Phone"
      return
    } else if (/iPad/gi.test(ua)) {
      config.device_id = "ipad"
      return
    }
  }
}

const initDeviceCode = () => {
  if (getStorage("deviceCode")) {
    const code = getStorage("deviceCode")
    config.device_code = code
    config.mac_code = code
  } else {
    const code = getUUID(16)
    setStorage("deviceCode", code)
    config.device_code = code
    config.mac_code = code
  }
}

const initSoftId = (soft_id) => {
  config.soft_id = soft_id
  setStorage("soft_id", soft_id)
}

const initCurrVer = (curr_ver) => {
  config.curr_ver = curr_ver
}

const initOs = (os) => {
  config.os = os
}

const initWxLoginAndPayDomain = (wxLoginAndPayDomain) => {
  config.wxLoginAndPayDomain = wxLoginAndPayDomain
}

const initQnwProjectDomain = (qnwProjectDomain) => {
  config.qnwProjectDomain = qnwProjectDomain
}

export {
  initSource,
  initEkey,
  initDeviceId,
  initDeviceCode,
  initSoftId,
  initCurrVer,
  initOs,
  initWxLoginAndPayDomain,
  initQnwProjectDomain,
}
