import config from "./config"
import {
  initSoftId,
  initOs,
  initCurrVer,
  initEkey,
  initSource,
  initDeviceId,
  initDeviceCode,
  initWxLoginAndPayDomain,
  initQnwProjectDomain,
} from "./config/init"
import {
  eventReportBaidu,
  installEventReport,
  onlineEventReport,
} from "./utils/event"
import { isDesktop, formatTime } from "./utils"
import { getStorage } from "./utils/storage"

const initConfig = async (configInfo) => {
  if (configInfo.soft_id) initSoftId(configInfo.soft_id)
  if (configInfo.os) initOs(configInfo.os)
  if (configInfo.curr_ver) initCurrVer(configInfo.curr_ver)
  if (configInfo.wxLoginAndPayDomain)
    initWxLoginAndPayDomain(configInfo.wxLoginAndPayDomain)
  if (configInfo.qnwProjectDomain)
    initQnwProjectDomain(configInfo.qnwProjectDomain)
  initEkey()
  initSource()
  initDeviceId()
  initDeviceCode()

  if (config.bdVid && config.strategy) eventReportBaidu()

  const device = isDesktop()
  const arr = [1, 3]
  if (arr.includes(device)) {
    const installStatus = getStorage("installStatus")
    const onlineTime = getStorage("onlineTime")
    if (installStatus != 1) {
      installEventReport()
    }
    if (onlineTime != formatTime(1)) {
      onlineEventReport()
    }
  }
}

export default initConfig
