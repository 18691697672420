import { isUni } from "./index";
import useUniStorage from "../environment/uni/storage";
import useVueStorage from "../environment/vue/storage";

const vueStroage = useVueStorage();
const uniStorage = useUniStorage();

const useStorage = isUni() ? uniStorage : vueStroage;

const getStorage = (key) => useStorage.getStorage(key);
const setStorage = (key, data) => useStorage.setStorage(key, data);
const removeStorage = (key) => useStorage.removeStorage(key);
const clearStorage = () => useStorage.clearStorage();

export { getStorage, setStorage, removeStorage, clearStorage };
