import http from "../utils/axios"
import config from "../config"
import jsonJoin from "../utils/jsonJoin"

// 百度回传事件
const eventReportBaiduApi = () => {
  const data = {
    bd_vid: config.bdVid,
    strategy: config.strategy,
    eventType: 3,
  }
  const params = {
    soft_id: config.soft_id,
    ekey: config.ekey,
    source: config.source,
  }
  return http.post(
    `${config.qnwProjectDomain}/api/popularize/baidu?${jsonJoin(params)}`,
    data
  )
}

// 安装事件
const installEventReportApi = () => {
  const params = {
    soft_id: config.soft_id,
    source: config.source,
    device_code: config.device_code,
    event_id: "install",
  }
  return http.get(
    `${config.qnwProjectDomain}/api/report/event?${jsonJoin(params)}`
  )
}

// 上线事件
const onlineEventReportApi = () => {
  const params = {
    soft_id: config.soft_id,
    source: config.source,
    device_code: config.device_code,
    event_id: "online",
  }
  return http.get(
    `${config.qnwProjectDomain}/api/report/event?${jsonJoin(params)}`
  )
}

export { eventReportBaiduApi, installEventReportApi, onlineEventReportApi }
