import {
  eventReportBaiduApi,
  installEventReportApi,
  onlineEventReportApi,
} from "../api/event"
import { formatTime } from "./index"
import { setStorage } from "./storage"

const eventReportBaidu = () => {
  return new Promise(async (resolve, reject) => {
    try {
      await eventReportBaiduApi()
      resolve({
        status: 1,
      })
    } catch (e) {
      reject({
        msg: "请求失败",
      })
    }
  })
}

const installEventReport = () => {
  return new Promise(async (resolve, reject) => {
    try {
      await installEventReportApi()
      resolve({
        status: 1,
      })
      setStorage("installStatus", 1)
    } catch (e) {
      reject({
        msg: "请求失败",
      })
    }
  })
}

const onlineEventReport = () => {
  return new Promise(async (resolve, reject) => {
    try {
      await onlineEventReportApi()
      resolve({
        status: 1,
      })
      setStorage("onlineTime", formatTime(1))
    } catch (e) {
      reject({
        msg: "请求失败",
      })
    }
  })
}

export { eventReportBaidu, installEventReport, onlineEventReport }
