<template>
  <div id="app">
    <div id="nav">
    <!--  <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    </div>
    <router-view/>
  </div>
</template>
<script>
	import initConfig from "../csdPublic/initConfig.js"
	export default {
		created(){
			const config = {
			  //项目 soft_id
			  soft_id: "wallpaper_win",
			  os: 1,
			  curr_ver: "1.0.0.1",
			  //微信登录、手机登录、支付
			  wxLoginAndPayDomain: "https://api.360qnw.com",
			  // 获取套餐、获取用户信息、事件上报
			  qnwProjectDomain: "https://api.360qnw.com",
			}
			initConfig(config)
		}
	}
	
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  /* padding: 30px; */
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.gestures{
	cursor: pointer;
}
*{ 
 -webkit-touch-callout:none; /*系统默认菜单被禁用*/
 -webkit-user-select:none; /*webkit浏览器*/
 -khtml-user-select:none; /*早期浏览器*/
 -moz-user-select:none;/*火狐*/
 -ms-user-select:none; /*IE10*/
 user-select:none; 
} 
input{ 
 -webkit-user-select:auto; /*webkit浏览器*/ 
}
textarea{
 -webkit-user-select:auto; /*webkit浏览器*/
 outline:none;
 border-width:0px;
}

input{
		outline:none;
		border-width:0px;
	}
	::-webkit-scrollbar {
	  width: 0 !important;
	}
	::-webkit-scrollbar {
	  width: 0 !important;height: 0;
	}
</style>
